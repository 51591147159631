<template>
  <RouterView />
  <LoginModal />
  <InviteCodeModal />
  <GuestQuestionModal />
  <CouponModal />
  <PointModal />
  <AdvertisementModal />
  <WelcomeNewUserModal />
  <MessageModal />
</template>
<script setup>
import GuestQuestionModal from '@/components/Modals/GuestQuestionModal.vue'
import LoginModal from '@/components/Modals/LoginModal.vue'
import InviteCodeModal from '@/components/Modals/InviteCodeModal.vue'
import CouponModal from '@/components/Modals/CouponModal.vue'
import PointModal from '@/components/Modals/PointModal.vue'
import AdvertisementModal from '@/components/Modals/AdvertisementModal.vue'
import WelcomeNewUserModal from '@/components/Modals/WelcomeNewUserModal.vue'
import MessageModal from '@/components/Modals/MessageModal.vue'
import { useDepositTypeStore } from '@/stores/depositType.js'
import { useMutexRuleStore } from '@/stores/mutexRule.js'
import { useSettingStore } from '@/stores/setting.js'
import { useNotificationStore } from '@/stores/notification'

//
const settingStore = useSettingStore()
const mutexRuleStore = useMutexRuleStore()
const depositTypeStore = useDepositTypeStore()
const notificationStore = useNotificationStore()
//
settingStore.fetch()
mutexRuleStore.fetch()
depositTypeStore.fetch()
notificationStore.listen()

</script>
