import { useSportStore } from '@/stores/common/sport.js'
import { api } from '@/plugins/axios'
import { echo } from '@/plugins/echo'
import { defineStore } from 'pinia'
import {ref} from 'vue'
import {groupBy} from 'lodash-es'

export const usePregameStore = defineStore('pregame', () => {
  const sportStore = useSportStore()
  const pregamesCount = ref([])

  async function fetch () {
    sportStore.fetching = true
    const { data } = await api.get('pregames')
    sportStore.games = new Map(data.data?.map(game => [game.id, game]))
    sportStore.fetching = false
  }

  async function fetchCount() {
    const { data } = await api.get('pregames')
    pregamesCount.value = groupBy(data.data, item => item.sport_id)

    return pregamesCount
  }

  async function subscribe () {
    echo.channel('pregame-event').listen('.updated', async () => {
      await fetch()
    })
  }

  async function unsubscribe () {
    echo.leaveChannel('pregame-event')
  }

  return {
    fetch,
    fetchCount,
    pregamesCount,
    subscribe,
    unsubscribe,
  }
})
