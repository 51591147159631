<template>
  <QDialog
    v-model="pointStore.showPointModal"
    persistent
  >
    <div class="relative px-10 w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-8 flex flex-col items-center justify-center gap-7">
        <QBtn
          v-close-popup
          icon="close"
          flat
          round
          dense
          class="absolute top-2 right-2"
        />
        <div class="text-sm font-black">
          포인트전환
        </div>
        <div class="text-5xl font-black mt-4">
          <CurrencyField :value="authStore.user.point_balance" />
        </div>
        <div class="text-base">
          POINT
        </div>
        <QBtn
          v-close-popup
          dense
          unelevated
          label="포인트전환"
          class="w-55 h-10"
          color="secondary"
          @click="onExchange"
        />
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import CurrencyField from '@/components/Tables/Fields/CurrencyField.vue'
import { useAuthStore } from '@/stores/auth'
import { usePointStore } from '@/stores/point'

const pointStore = usePointStore()
const authStore = useAuthStore()

function onExchange () {
  pointStore.exchange()
  authStore.user.wallet_balance += authStore.user.point_balance
  authStore.user.point_balance = 0
}
</script>
